import {
  Grid,
  Box,
  Typography,
  IconButton,
  Container,
  Icon,
  Button,
} from "@mui/material";
import QuantityButton from "./QuantityButton";
import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import showimgproduct from "../../assets/imgcheckout/S__285024404_0.jpg";
import showimgproduct1 from "../../assets/imgcheckout/S__285024406_0.jpg";
import showimgproduct2 from "../../assets/imgcheckout/S__285024407_0.jpg";
import showimgproduct3 from "../../assets/imgcheckout/S__285024408_0.jpg";
import showimgproduct4 from "../../assets/imgcheckout/S__285024409_0.jpg";
import showimgproduct5 from "../../assets/imgcheckout/S__285024410_0.jpg";
import showimgproduct6 from "../../assets/imgcheckout/S__285024411_0.jpg";
import showimgproduct7 from "../../assets/imgcheckout/S__285024412_0.jpg";
import showimgproduct8 from "../../assets/imgcheckout/S__285024413_0.jpg";
import showimgproduct9 from "../../assets/imgcheckout/S__285024414_0.jpg";
import showimgproduct10 from "../../assets/imgcheckout/S__285024415.jpg";

const ListImgQuantity = [
  {
    id: 1,
    photo: showimgproduct,
  },
  {
    id: 2,
    photo: showimgproduct1,
  },
  {
    id: 3,
    photo: showimgproduct2,
  },
  {
    id: 4,
    photo: showimgproduct3,
  },
  {
    id: 5,
    photo: showimgproduct4,
  },
  {
    id: 6,
    photo: showimgproduct5,
  },
  {
    id: 7,
    photo: showimgproduct6,
  },
  {
    id: 8,
    photo: showimgproduct7,
  },
  {
    id: 9,
    photo: showimgproduct8,
  },
  {
    id: 10,
    photo: showimgproduct9,
  },
  {
    id: 30,
    photo: showimgproduct10,
  },
];

const between = [
  {
    id: 30,
    photo: showimgproduct9,
  },
];
interface Product {
  id: number;
  photo: string;
}
const ProductComponent = (props: any) => {
  const [product, setProduct] = useState<any>({});
  const [productPath, setProductPath] = useState<any>([]);

  useEffect(() => {
    if (props.product !== undefined) {
      setProduct(props.product);
    }
  }, [props.product]);

  // const handlePlusQuantity = () => {
  //     let temp_product = product;
  //     if((temp_product.quantity + 1) > 10)
  //     {
  //         return;
  //     }

  //     const prod = ListImgQuantity.filter((Quantity) => Quantity.id === product.quantity+1);
  //     temp_product.quantity += 1;
  //     props.updateProductByProductComponent(temp_product);
  //     if (product.quantity >= 10 && product.quantity < 30) {
  //         setProductPath(between)
  //     } else {
  //         setProductPath(prod)
  //     };

  //     // console.log(product.quantity)
  //     // console.log(prod[0]?.id)

  // }

  // const handleMinusQuantity = () => {
  //     let temp_product = product;
  //     const prod = ListImgQuantity.filter((Quantity) => Quantity.id === product.quantity+1);
  //     temp_product.quantity = (temp_product.quantity - 1) < 0 ? 0 : temp_product.quantity - 1;
  //     props.updateProductByProductComponent(temp_product);
  //     if (product.quantity >= 10 && product.quantity < 30) {
  //         setProductPath(between)
  //     } else {
  //         setProductPath(prod)
  //     }
  //     console.log(product.quantity)
  //     console.log(prod[0]?.id)

  // }

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 30];

  const handleChangeAmount = (e: number) => {
    let temp_product = { ...product }; // Create a copy to avoid mutating state directly

    temp_product.quantity = e;
    props.updateProductByProductComponent(temp_product);

    // Update the product path based on the new quantity
    const prod =
      ListImgQuantity.find((item) => item.id === temp_product.quantity) || {};
    setProductPath(
      temp_product.quantity >= 10 && temp_product.quantity < 30
        ? between
        : [prod]
    );
  };

  const handlePlusQuantity = () => {
    let temp_product = { ...product }; // Create a copy to avoid mutating state directly
    if (temp_product.quantity + 1 > 30) {
      return;
    }

    temp_product.quantity += 1;
    props.updateProductByProductComponent(temp_product);

    // Update the product path based on the new quantity
    const prod =
      ListImgQuantity.find((item) => item.id === temp_product.quantity) || {};
    setProductPath(
      temp_product.quantity >= 10 && temp_product.quantity < 30
        ? between
        : [prod]
    );
  };

  const handleMinusQuantity = () => {
    let temp_product = { ...product }; // Create a copy to avoid mutating state directly
    if (temp_product.quantity - 1 < 0) {
      return;
    }

    temp_product.quantity -= 1;
    props.updateProductByProductComponent(temp_product);

    // Update the product path based on the new quantity
    const prod =
      ListImgQuantity.find((item) => item.id === temp_product.quantity) || {};
    setProductPath(
      temp_product.quantity >= 10 && temp_product.quantity < 30
        ? between
        : [prod]
    );
  };

  return product.name !== undefined ? (
    <Grid item xs={12} sm={12} md={12} lg={12} key={props.index}>
      <Box
        sx={{
          background:
            "linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));",
          border: "solid 1px rgba(255, 255, 255, 1)",
          minHeight: 300,
          mt: 3,
          mb: 3,
          borderRadius: 5,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          rowGap: "22px",
          p: "12px 0",
        }}
      >
        <Grid item mx={"auto"} width={{ xs: "90%", sm: "50%" }}>
          <Grid
            container
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item>
              <Box display={`flex`} justifyContent={`center`}>
                <Typography
                  py={3}
                  pb={0}
                  fontSize={28}
                  fontWeight={`bold`}
                  sx={{
                    color: "#A78241",
                    textShadow:
                      "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
                  }}
                >
                  {product.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display={`flex`} justifyContent={`center`}>
                {/* <QuantityButton
                  sx={{
                    mt: 3,
                    mb: 3,
                    xs: {
                      width: `100%`,
                    },
                    sm: {
                      width: `100%`,
                    },
                  }}
                  quantity={product.quantity}
                  handlePlusQuantity={handlePlusQuantity}
                  handleMinusQuantity={handleMinusQuantity}
                /> */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "5px",
                    padding: "5%",
                  }}
                >
                  {numbers.map((number) => (
                    <Button
                      key={number}
                      onClick={() => handleChangeAmount(number)}
                      style={
                        product.quantity === number
                          ? { backgroundColor: "#FECE08" }
                          : { backgroundColor: "#8a5834" }
                      }
                      sx={{
                        height:"40px",
                        fontSize:"18px",
                        color: "white",
                      }}
                    >
                      {number}
                    </Button>
                  ))}
                </div>
              </Box>
            </Grid>
            <Grid item display={`flex`} justifyContent={`center`}>
              <Box
                sx={{
                  transition: "all .2s",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <img
                  src={product.image.replace(
                    `{api_path}`,
                    process.env.REACT_APP_API_URL
                  )}
                  style={{
                    maxHeight: "281px",
                  }}
                  alt={product.name}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mx={"auto"}
          width={{ xs: "90%", sm: "50%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {/* <Grid item xs={12} sm={12} md={12} lg={12} position={`relative`} maxWidth={`100%`}>
                        <Box position={`absolute`} display={`flex`} justifyContent={`flex-end`} width={`100%`}>
                            <Box pr={1} pt={1}>
                                <IconButton sx={
                                    {
                                        color: "rgba(255, 0, 0, .5)",
                                        minWidth: 'auto',
                                        backgroundColor: "rgba(255, 255, 255, .5)",
                                        borderRadius: 5,
                                        transition: 'all .2s',
                                        "&:hover": {
                                            backgroundColor: "rgba(255, 0, 0, .5)",
                                            color: "rgba(255, 255, 255, 8)",
                                        }
                                    }
                                } onClick={() => {

                                }}>
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid> */}
          {product.quantity > 0 ? (
            <img
              src={productPath[0]?.photo}
              width={"70%"}
              style={{ margin: "0 auto" }}
            />
          ) : null}
        </Grid>
      </Box>
    </Grid>
  ) : null;
};

export default ProductComponent;
