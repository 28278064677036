import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProductComponent from "../components/Checkout/ProductComponent";
import { Box, Grid, Typography, Container } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TotalPrice from "../components/Checkout/TotalPrice";
import Express from "../components/Checkout/Express";
import Payment from "../components/Checkout/Payment";
import checkout1 from "../assets/images/chackout.jpg";
import { gauvainAPI } from "../api/gauvain";
import Car from "../assets/images/car.png";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { usePayment } from "../context/PaymentContextProvider";

import emo from "../assets/images/emo.png";

// import snack from "../assets/images/0ccb33d77590a63746bdaafae92018bc.png"
   

const Checkout = () => {
  const { fnCreateOrder, handleTotalItem } = usePayment();

  const [productList, setProductList] = useState<any>([]);

  const [currentAddres, setCurrentAddress] = useState<any>(null);
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();

  const [toggleDes, setToggleDes] = useState(0);

  const handleToggle = (id: any) => setToggleDes(id - 1);
  handleTotalItem(productList);

  useEffect(() => {
    (async () => {
      if (!cookies.get("access_token")) {
        return navigate(`/login`);
      }

      try {
        const api = new gauvainAPI();
        const allProductsTest: any = await api.getAllProducts();

        if (
          allProductsTest.data.status_code !== undefined &&
          allProductsTest.data.status_code === 200
        ) {
          const temp_productList: any = [];

          await Promise.all(
            allProductsTest.data.products.map((product: any) => {
              let temp_product = product;
              temp_product.quantity = 0;

              temp_productList.push(temp_product);
            })
          );

          setProductList(temp_productList);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const buyProducts = async ({ isCOD }: { isCOD: boolean }) => {
    try {
      const data = productList;
      data.push({ address: currentAddres });
      await fnCreateOrder(data, isCOD);
    } catch (err) {
      console.log(err);
    }
  };

  const updateProductByProductComponent = (updateProduct: any) => {
    let temp_productList: any = [];
    temp_productList = [...productList];

    const findIndex = temp_productList.findIndex(
      (element: any) => element.id === updateProduct.id
    );

    if (findIndex === -1) {
      return;
    }

    temp_productList[findIndex] = updateProduct;
    setProductList(temp_productList);
  };

  return (
    <Box
      sx={{
        // backgroundColor: "#a7d1dd",
        backgroundColor: "#B9DDEC",
        color: "white",
      }}
    >
      <Container>
        <Box display={`flex`} justifyContent={`center`}>
          <Typography
            pt={3}
            fontSize={28}
            fontWeight={`bold`}
            sx={{
              color: "#454545",
              textShadow:
                "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
            }}
          >
            <ShoppingCartIcon
              sx={{
                color: "white",
              }}
            />{" "}
            สั่งซื้อ
          </Typography>
        </Box>
        {productList.length > 0 && (
          <Grid container>
            {productList.map((product: any, index: number) => (
              <ProductComponent
                key={index}
                product={product}
                updateProductByProductComponent={
                  updateProductByProductComponent
                }
              />
            ))}
          </Grid>
        )}
        <TotalPrice productList={productList} />
        <Express
          currentAddres={currentAddres}
          setCurrentAddress={setCurrentAddress}
        />
        <Payment buyProducts={buyProducts} />
      </Container>
      <Container sx={{ marginTop: 6 }}>
        <Box>
          <Typography
            color={"#333"}
            fontSize={22}
            sx={{
              color: "black",
              textShadow:
                "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
              ml: 3,
              mb: 2,
            }}
          >
            รายละเอียดสินค้า
          </Typography>
          <Grid container spacing={2} mb={2}>
            {productList?.map((pd: any) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={pd.id}>
                <Box
                  sx={{
                    backgroundColor: `${pd?.background_color}`,
                    borderRadius: "12px",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: `0 8px 16px rgba(0, 0, 0, 0.2)`,
                      cursor: "pointer",
                    },
                  }}
                >
                  <Box
                    onClick={() => handleToggle(pd.id)}
                    sx={{
                      backgroundColor: `${pd?.background_color}`,
                      paddingY: 2,
                      color: "white",
                      // border: `1px dashed ${pd?.background_color}`,
                      borderRadius: "12px",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                  >
                    <Typography
                      color={"#333"}
                      fontSize={18}
                      sx={{
                        textAlign: "center",
                        color: `#000`,
                        textShadow:
                          "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
                      }}
                    >
                      {pd.name}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: 4,
            borderRadius: "16px",

            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            fontSize: "14px",
            lineHeight: "1.6",
            color: "#333",
          }}
          dangerouslySetInnerHTML={{
            __html: productList?.[toggleDes]?.description,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            right: { xs: 10, md: 300 }, // Right 0 on small screens, 300px on medium and larger screens
            zIndex: 10,
            transform: "translateY(-360px)", // Move up by 310px
          }}
        >
          <img
            src={emo}
            alt="emoji"
            style={{
              width: "220px",
            }}
           
          />
        </Box>
      </Container>
      <Grid container>
        <img src={checkout1} alt="checkout1" width={"100%"} />
      </Grid>
      <Grid
        container
        p={2}
        sx={{ background: "#EDC26E", overflow: "hidden" }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          xs={6}
          sx={{ display: { xs: "none", md: "block" } }}
          textAlign={"center"}
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <img src={Car}></img>
        </Grid>
        <Grid xs={12} md={6} data-aos="fade-left">
          <Typography variant="h6">
            <b style={{ color: "#FFF" }}>ดูแลตัวเองในรูปแบบใหม่ “ Gauvain “</b>
            เครื่องดื่มโปรตีนถั่วลันเตาสีทอง ชงง่ายละลายไว อิ่มท้อง ทานง่าย
            ไฟเบอร์สูง
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Checkout;
